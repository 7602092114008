.mq-editable-field {
  border: 0;
  box-shadow: none;
}

.mq-root-block,
.mq-math-mode .mq-root-block {
  padding: 0;
  padding-left: 1px;
  vertical-align: bottom;
}

.mq-editable-field.mq-focused {
  box-shadow: none;
  background-color: #e4f2fe;
}

.mq-sup,
.mq-sub {
  font-size: 70%;
}
